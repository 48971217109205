<template>
  <div class="app-container">

    <div class="top_select">
      <div>
        <el-button @click="handleCreate()" type="primary" size="small" plain :disabled="!$buttonStatus('rygl_xz')">新增成员</el-button>
        <el-cascader
          change-on-select
          placeholder="请选择部门"
          :options="options"
          :props=props
          v-model="listQuery.dept" size="small"
          style="margin:0 10px;">
        </el-cascader>

        <el-input style="width:200px;margin-right:10px;" v-model="listQuery.cnName" placeholder="请输入姓名" size="small"></el-input>
        <el-button class="filter-item" type="primary" icon="Search" @click="handleFilter" size="small" plain>搜索</el-button>
      </div>
      <div>
      </div>
    </div>

    <el-table ref="table" :height="contentStyleObj"  v-loading="listLoading"  :data="list" border  style="width: 100%;"  @selection-change="handleSelectionChange">
      <!-- <el-table-column type="selection" align="center"/> -->
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="序号" align="center" width="55" type="index"></el-table-column>
      <el-table-column label="姓名" align="center" min-width="120">
        <template #default="scope">
          <span>{{ scope.row.cnName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="部门" align="center" min-width="80">
        <template #default="scope">
          <span>{{ scope.row.deptName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户名（手机号）" align="center" width="150">
        <template #default="scope">
          <span>{{ scope.row.userName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="密码" align="center" min-width="120">
        <template #default="scope">
          <span>{{ scope.row.password }}</span>
          <el-button type="primary" size="small" @click="reset(scope.row)" plain v-if="isAdmin == 1" style="margin-left: 10px;">重置</el-button>
        </template>
      </el-table-column>
      
      <el-table-column label="手机号" align="center" width="150">
        <template #default="scope">
          <span>{{ scope.row.mobile }}</span>
        </template>
      </el-table-column>

      <el-table-column label="是否主管" align="center" width="150">
        <template #default="scope">
          <span v-if="scope.row.isLeader == 1">{{ scope.row.deptName }} - 主管</span>
          <span v-else>否</span>
        </template>
      </el-table-column>

      <el-table-column label="角色" align="center" width="180">
        <template #default="scope">
          <span>{{ scope.row.roleNames }}</span>
        </template>
      </el-table-column>

      <!-- <el-table-column label="别名" align="center" width="260">
        <template #default="scope">
          <div>
            <el-input placeholder="" v-model="scope.row.remark" class="filter-item" size="small" style="width: 200px;height: 24px;" />
            <el-button type="success" size="small" @click="aliasSave(scope.row)" plain>保存</el-button>
          </div>
        </template>
      </el-table-column> -->

      <el-table-column label="操作" align="center" width="350" class-name="small-padding fixed-width">
        <template #default="scope">
          <el-button type="primary" @click="handleUpdate(scope.row)" size="small" plain :disabled="!$buttonStatus('rygl_bj')" icon="Edit">编辑</el-button>
        <el-button type="primary" @click="saverole(scope.row)" size="small" plain :disabled="!$buttonStatus('rygl_jsgl')" icon="Setting">设置角色</el-button>
          <el-button size="small" @click="del(scope.row)" type="danger" plain :disabled="!$buttonStatus('rygl_sc')" icon="Delete">删除</el-button>
          <el-button v-if="scope.row.status == 1" size="small" @click="saveStatus(scope.row, 2)" type="danger" plain :disabled="!$buttonStatus('rygl_ty')">停用</el-button>
          <el-button v-else size="small" @click="saveStatus(scope.row, 1)" plain :disabled="!$buttonStatus('rygl_qy')">启用</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 弹框 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close :title="textMap[dialogStatus]" v-model="dialogFormVisible"  class="button_bg" width="390px">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="90px">
        <div class="common_dialog_box clearfix">
          <div class="item_one clearfix">
            <el-form-item label="部门" prop="dep">
              <el-cascader change-on-select :options="options" :props=props v-model="temp.dep" style="width:180px;" size="small" ></el-cascader>
              <!-- <el-select v-model="temp.dep" placeholder="请选择" size="small">
                <el-option v-for="item in options.children" :key="item.id" :label="item.label" :value="item.id">
                </el-option>
              </el-select> -->
            </el-form-item>
            
            <el-form-item label="姓名" prop="cnName">
              <el-input v-model="temp.cnName" placeholder="请输入内容" style="width:180px;" size="small" ></el-input>
            </el-form-item>
            
            <el-form-item v-if="dialogStatus != 'create'" label="修改账号" prop="cnName">
              <el-radio-group v-model="temp.type">
                <el-radio :label="1">不修改</el-radio>
                <el-radio :label="2">修改</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="手机号" prop="userName">
              <el-input :disabled="temp.type == 1" v-model="temp.userName" placeholder="请输入手机号码" style="width:180px;" size="small" ></el-input>
            </el-form-item>
            <el-form-item v-if="dialogStatus == 'create' || temp.type == 2" label="验证码" prop="yzm">
              <el-input v-model="temp.yzm" placeholder="请输入验证码" style="width:98px;" size="small" ></el-input>
              <el-button size="small" v-if="count" style="width:82px;">{{count}}</el-button>
              <el-button size="small" v-else @click="getYzm" style="width:82px;">{{text}}</el-button>
            </el-form-item>

            <el-form-item label="是否主管" v-if="isAdmin == 1">
              <el-switch
                v-model="temp.isLeader">
              </el-switch>
            </el-form-item>
          </div>
        </div>

      </el-form>


      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisible = false" size="small">取消</el-button>
          <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()" size="small">确定</el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 弹框 -->

    <!-- 角色弹框 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close title="设置角色" v-model="dialogFormVisiblerole"  class="button_bg">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="left" label-width="70px" style="width: 700px; margin:0 auto">
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="checkedCities" size="small">
          <el-checkbox v-for="city in cities" :label="city.id" :key="city.id">{{city.name}}</el-checkbox>
        </el-checkbox-group>
        
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisiblerole = false" size="small">取消</el-button>
          <el-button type="primary" @click="updaterules()" size="small">确定</el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 角色弹框 -->

    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>
    
  </div>
</template>

<script>
import { getBaseDepts,getUsers,getSysRoles,saveUser,deleteUser,userBindingRole,saveUserStatus,resetPasswords } from '@/api/sys.js'
import { sendSms } from '@/api/login.js'

export default {
  name: 'user-user',
  data() {
    return {
      isAdmin: this.$store.getters["user/user"].isAdmin,
      pass4: true,
      props: {
        label: 'label',
        value: 'id',
        // multiple: true,
        checkStrictly: true
      },
      checkAll: false,
      checkedCities: [],
      cities: ['上海', '北京', '广州', '深圳'],
      isIndeterminate: true,
      selectedOptions: [],
      // listLoading: true,
      options: [],
      list: [],
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '修改人员信息',
        create: '新增人员信息'
      },
      listQuery: {
        page: 1,
        limit: 20,
        deptId: 0
      },
      rules: {
        cnName: [{ required: true, message: '姓名不能为空', trigger: 'change' }],
        yzm: [{ required: true, message: '验证码不能为空', trigger: 'change' }],
        userName: [{ required: true, message: '请输入用户名', trigger: 'change' },{ min: 11, max: 11, message: '请输入正确手机号', trigger: 'blur' }],
        dep: [{ required: true, message: '请选择部门', trigger: 'change' }],
        password: [{ required: true, message: '请输入密码', trigger: 'change' }],
      },
      dialogFormVisiblerole: false,
      dialogPvVisible: false,
      temp: {
        type: 1,
        yzm: '',
        cnName: '',
        userName: '',
        dep: '',
        mobile: '',
        password: '',
        isLeader: false,
      },
      contentStyleObj:{}, //高度变化
      text: "获取验证码",
      count: 0,
      sels:[]
    }
  },
  created() {
    // 获取表格高度
    this.contentStyleObj=this.$getHeight(255)
    window.addEventListener('resize', this.getHeight);
    this.init()
    this.getList()
    this.initBus()
  },
  // 销毁监听事件，从这个页面离开就销毁监听事件
  // destroyed(){
  //   window.removeEventListener('resize', this.getHeight)
  // },
  methods:{
    initBus(){
      this.$bus.off("addUpdate")
      this.$bus.on("addUpdate", (val) => {
        this.init()
      });
    },
    saveStatus(row, status){
      saveUserStatus({id: row.id, status: status}).then(res=>{
        if(res.data.msg == "success"){
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.getList()
        }
      })
    },
    // aliasSave(row){
    //   saveAlias({id: row.id, remark: row.remark}).then(res=>{
    //     if(res.data.msg == "success"){
    //       this.$message({
    //         message: '修改成功',
    //         type: 'success'
    //       })
    //     }
    //   })
    // },
    getCode(){
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
          this.count--;
          } else {
          this.show = true;
          clearInterval(this.timer);
          this.timer = null;
          }
        }, 1000)
      }
    },
    getYzm(){
      if(!(/^1[3456789]\d{9}$/.test(this.temp.userName))){
        this.$message({
          type: 'info',
          message: '请输入正确手机号'
        }); 
        return
      }
      this.getCode()
      sendSms({"mobile": this.temp.userName}).then(res=>{
        if(res.data.msg != "success"){
          return
        }
        this.$message({
          message: '发送成功',
          type: 'success'
        })
      })
    },
    savepass4(e){
      e.password = ""
      this.pass4 = !this.pass4
    },
    del(row) {
      this.$confirm('确认删除该用户', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteUser({id:row.id}).then(res => {
          if(res.data.msg == "success"){
            if(res.data.data.list && res.data.data.list.length != 0){
              this.$qzfMessage('删除成功，该账号下的公司已自动分配到主账号')
            }else{
              this.$message({
                message: '删除成功',
                type: 'success'
              })
            }
            this.$bus.emit('personUpdate')
            this.getList()
          }    
        })
      })
    },
    handleFilter() {
      if(this.listQuery.dept){
        this.listQuery.deptId = this.listQuery.dept[this.listQuery.dept.length-1]
      }
      this.listQuery.page = 1
      this.getList()
    },
    getList() {
      this.listLoading = true
      // this.$nextTick(() => {
      //   this.$refs.table.bodyWrapper.scrollTop = 0
      // })
      getUsers(this.listQuery).then(response => {
        this.list = response.data.data.list
        this.total = response.data.data.total

        // Just to simulate the time of the request
        // setTimeout(() => {
          this.listLoading = false
        // }, 1.5 * 1000)
      })
    },
    updaterules() {
      if(this.checkedCities.length == 0){
        this.$message({
          type: 'info',
          message: '请选择角色'
        }); 
        return false
      }
      let arr = this.checkedCities.map(v=>{
        return {userId:this.temp.id*1,roleId:v*1}
      })
      userBindingRole(arr).then(res=>{
        if(res.data.msg == "success"){
          this.$message({
            message: '绑定成功',
            type: 'success'
          })
          this.getList();
          this.dialogFormVisiblerole = false
        }
      })
    },
    handleCheckAllChange(val) {
      let cic = this.cities.map(v=>{
        return v.id
      })
      this.checkedCities = val ? cic : [];
      this.isIndeterminate = false;
    },
    init() {
      this.listLoading = false
      getBaseDepts({}).then(res => {
        // let big = res.data.data.list
        let big = res.data.data.list[0].children
        // this.id = big.id
        // big.map(v =>{
        //   items = v
        // })
        this.options =big
        // this.options =items
      })
      getSysRoles({}).then(response => {
        this.cities = response.data.data.list
      })
    },
    resetTemp() {
      this.temp = {
        type: 2,
        cnName: '',
        userName: '',
        dep: [],
        mobile: '',
        password: '',
        isLeader: false,
      }
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let num = this.temp.dep.length-1
          this.temp.deptId = this.temp.dep[num]
          this.temp.deptPosition = this.temp.dep.join('-')
          if(this.temp.isLeader){
            this.temp.isLeader = 1
          }else{
            this.temp.isLeader = 2
          }

          this.$confirm('我们将以短信的形式把账号和随机密码发送至您的手机上, 请确认账号是否输入正确?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            saveUser(this.temp).then(res => {
              if(res.data.msg != "success"){
                return
              }
              this.dialogFormVisible = false
              this.$notify({
                title: '成功',
                message: '创建成功',
                type: 'success',
                duration: 2000
              })
              this.$bus.emit('personUpdate')
              this.getList()
            })
          })
        }
      })
    },
    handleUpdate(row) {
      // return
      this.temp = Object.assign({}, row) // copy obj
      if(this.temp.isLeader == 1){
        this.temp.isLeader = true
      }else{
        this.temp.isLeader = false
      }
      let dep = this.temp.deptPosition.split('-')
      let arr = dep.map(v=>{
        return Number(v)
      })
      this.temp.dep = arr
      this.temp.type = 1
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          const tempData = Object.assign({}, this.temp)
          let num = this.temp.dep.length-1
          tempData.deptId = this.temp.dep[num]
          tempData.deptPosition = this.temp.dep.join('-')
          if(tempData.isLeader){
            tempData.isLeader = 1
          }else{
            tempData.isLeader = 2
          }
          saveUser(tempData).then(res => {
            if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.$bus.emit('personUpdate')
            this.getList()
            this.dialogFormVisible = false
          }
          })
        }
      })
    },
    saverole(row) {
      this.temp = Object.assign({}, row) // copy obj
      this.checkedCities = this.checkedCities.map(v=>{
        return Number(v)
      })
      this.checkedCities = this.temp.roleIds.split(',').map((item) => item*1)

      this.dialogFormVisiblerole = true
    },
    reset(row){
      this.$confirm('确定重置密码为888888?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        resetPasswords({
          id:row.id
        }).then(res => {
          if(res.data.msg == 'success'){
            this.$qzfMessage('重置成功');
            this.getList()
          }
        })
      })
    },
    handleSelectionChange(sels) {
      this.sels = sels;
    },
    //批量角色管理
    allotRole(){
      if(this.sels.length == 0){
        this.$qzfMessage('请选择人员',1)
        return
      }
      this.dialogFormVisiblerole = true
    }
  },

}
</script>

<style>
.no_bor_input .el-input__inner{
  border-radius: 0;
}
</style>

<style lang="scss" scoped>
.el-checkbox{
  width: 160px !important;
  margin-right: 20px;
  margin-bottom: 8px;
}
.el-checkbox:nth-child(4n-1){
  margin-right: 0;
}
.el-checkbox+.el-checkbox{
  margin-left: 0;
}
.xingxing{
  position: relative;
    .savepassi{
      position: absolute;
      right: 20px;
      top: 2px;
      font-size: 16px;
      width: 16px;
    }
    background: #fff6f7;
    height: 28px;
    vertical-align: top;
    line-height: 28px;
    text-indent: 1em;
    border: 1px solid #c2c2c2;
    width: 353px;
    display: inline-block;
    box-sizing: border-box;
    margin-bottom: 10px;
    i{
      margin-top: 3px;
      color: #fe6c6f;
    }
}
.top_select{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
