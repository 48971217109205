<template>
  <div class="app-container">
    <div style="display: flex;justify-content: space-between;">
      <div>
        <cascader-common
          v-model:modelValue="listQuery.businessTypeId"
          placeholder="请选择业务类型"
          :options="contractTypeOptions"
          :casProps="casProps"
          :clearable="true"
          style="width: 260px"
        />
        <el-button
          type="primary"
          @click="getList"
          size="small"
          style="margin-right: 5px"
          icon="Search"
          >搜索</el-button
        >
      </div>
      <div>
        <el-button
          type="primary"
          @click="addTask"
          size="small"
          icon="Plus"
          >新增</el-button
        >
        <el-button
          type="primary"
          @click="delFlowBatch"
          size="small"
          icon="Delete"
          >批量删除</el-button
        >
      </div>
    </div>
    <!-- 列表 -->
    <div>
      <el-table
        :data="taskList"
        style="width: 100%; margin-top: 10px"
        border
        :height="contentStyleObj"
        @selection-change="handleSelectChange"
        v-loading="listLoading"
        stripe
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column type="selection" width="55" align="center" fixed />
        <el-table-column
          prop="business_parent_name"
          label="业务大类"
          min-width="100"
          align="left"
        />
        <el-table-column
          prop="business_type_name"
          label="业务类型"
          min-width="120"
          align="left"
        >
          <template #default="scope">
            <span @click="editTask(scope.row)" class="custor">{{ scope.row.business_type_name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="contactsNa"
          label="办理流程"
          min-width="200"
          align="left"
        >
          <template template #default="scope">
            <div class="item" v-if="scope.row.stepList">
              <div v-for="(item,index) in scope.row.stepList" :key="item.id" style="display: flex;align-items: center;">
                <div class="custor" @click="editStep(item)">
                  {{ item.stepName }}
                </div>
                <div style="margin: 0 5px;padding-top: 3px;" v-if="index+1 != scope.row.stepList.length">
                  <el-icon><ArrowRight /></el-icon>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          min-width="80"
          align="left"
        />
        <el-table-column
          prop="inputClerkName"
          label="录入人"
          min-width="100"
          align="left"
        />
        <el-table-column
          label="操作"
          align="center"
          width="150"
          fixed="right"
        >
          <template #default="scope">
            <el-button
              type="text"
              size="small"
              @click="copyFlow(scope.row)"
              style="margin: 0;padding: 5px 2px"
              icon="CopyDocument"
            >复制</el-button>
            <el-button
              type="text"
              size="small"
              @click="delFlow(scope.row)"
              style="margin: 0;padding: 5px 2px"
              icon="Delete"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="page_container">
      <qzf-pagination
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList()"
      />
    </div>

    <!-- 新增 -->
    <div>
    </div>
  </div>

  <!-- 选择资料 -->
  <selectinformation ref="selectinformation" @success="getList"></selectinformation>
  <!-- 新增业务 -->
  <businessform ref="businessform" @success="getList"></businessform>
</template>

<script>
import { categoryListNew, } from "@/api/crm";
import selectinformation from "../clue/components/selectInformation.vue"
import businessform from "./components/businessForm.vue"
import { contractTypeList,flowList,flowDel } from "@/api/newCrm.js";

export default {
  name: '',
  components: {
    selectinformation,
    businessform
  },
  props: {},
  data () {
    return {
      listQuery: {
        page: 1,
        limit: 20,
        businessTypeId: null,
      },
      taskList: [],
      total: 0,
      options: [],
      categoryList: [],
      form: {
        server: []
      },
      addDrawer: false,
      taskForm: {
        newCrmFlowStepList:[],
      },
      rules: {
        businessTypeId: [
          { required: true, message: '请选择业务类型', trigger: 'blur' }
        ],
      },
      infoOpen: false,
      contractTypeOptions:[],//业务类型
      casProps:{
        expandTrigger: "hover",
        label: "name",
        value: "id",
      },
      selection: [],
      ids: [],
    }
  },
  computed: {},
  watch: {},
  created () {
    this.contentStyleObj = this.$getHeight(318);
    this.getList()
  },
  mounted () {
    this.getBussList()
  },
  methods: {
    getBussList(){
      contractTypeList({ contractType: '100' }).then((res) => {
        this.contractTypeOptions = res.data.data.list;
      });
    },
    getList(){
      flowList(this.listQuery).then((res) => {
        this.taskList = res.data.data.data
        this.total = res.data.data.total
      })
    },
    addTask(){
      this.$refs.businessform.init('add')
    },
    copyFlow(val){
      val.id = null
      if(val.stepList){
        val.stepList.forEach(item =>{
          item.id = null
        })
      }
      this.$refs.businessform.init('edit',val)
    },
    delFlow(row){
      this.$confirm('确认删除该流程？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        flowDel({id: [row.id]}).then((res) => {
          if (res.data.msg == "success") {
            this.$message({
              type:'success',
              message: '删除成功'
            });
            this.getList()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleSelectChange(l) {
      this.selection = l
      this.ids = l.map(l => l.id)
    },
    delFlowBatch(){
      if (!this.ids.length) return this.$message.warning('请选择要删除的流程')
      this.$confirm('确认删除所选流程？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        flowDel({id: this.ids}).then((res) => {
          if (res.data.msg == "success") {
            this.$message({
              type:'success',
              message: '删除成功'
            });
            this.getList()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    editTask(val){
      this.$refs.businessform.init('edit',val)
    },
    editStep(val){
      this.$refs.selectinformation.init('step',val)
    },
     // 业务类型相关操作
     getOptions() {
      // 初始化 options
      this.options = [];

      // 确保 categoryList 和 form.server 都存在
      if (!this.categoryList || !this.form.server) return;

      // 创建一个映射表用于快速查找
      const serverMap = new Map();
      this.form.server.forEach((item) => {
        if (!item.id) {
          // 设置 disposable 属性
          item.disposable = item.categoryNameId === 5 ? "0" : "1";
        }
        // 使用 Map 存储 id 对应的 item
        serverMap.set(item.categoryNameId, item);
      });

      // 遍历 categoryList 并添加符合条件的子项
      this.categoryList.forEach((v) => {
        v.children.forEach((k) => {
          if (serverMap.has(k.id)) {
            this.options.push(k);
          }
        });
      });

      // 去重
      this.options = [...new Set(this.options.map((option) => option.id))].map(
        (id) => {
          return this.options.find((option) => option.id === id);
        }
      );
    },

    getCategory() {
      categoryListNew({}).then((res) => {
        if (res.data.msg == "success") {
          this.categoryList = res.data.data.list ? res.data.data.list : [];
          this.getOptions();
        }
      });
    },
  }
}
</script>

<style scoped lang="less">
.drawerHead{
  display: flex;
  justify-content: space-between;
}
.w200{
  width: 200px;
}
.content {
  width: 92%;
  overflow-x: auto; /* 启用水平滚动条 */  
  white-space: nowrap; /* 防止内容换行 */ 
  min-height: 72px;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  position: relative;
  span {
    line-height: 28px;
    font-size: 13px;
  }
  tr {
    padding: 0;
    margin: 0;
  }
  td {
    border-right: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    height: 32px;
    padding: 0 6px;
    color: #333;
    font-size: 13px;
  }
}
:deep(.el-input-group__append, .el-input-group__prepend){
  padding: 0 8px;
}
.item{
  display: flex;
  align-items: center;
}
.item :deep(.el-icon svg){
  background-color: #17A2B8;
  border-radius: 50%;
  color: aliceblue;
}
.page_container{
  float: right;
  margin-top: 10px;
}
.custor{
  cursor: pointer;
  color: #17A2B8;
}
</style>
