<template>
  <div class="app">
    <el-drawer
      v-model="addDrawer"
      :before-close="handleClose"
      :close-on-click-modal="false"
      size="700px"
    >
      <template #header>
        <div class="drawerHead">
          <div>
            业务流程
          </div>
          <div>
            <el-button
              type="primary"
              @click="saveTask"
              size="small"
              style="margin-right: 5px"
              >保存</el-button
            >
          </div>
        </div>
      </template>
      <template #default>
        <el-form :model="taskForm" :rules="rules" ref="taskForm" label-width="100px" :inline="true">
          <el-form-item label="业务类型:" prop="businessTypeId">
            <cascader-common
              v-model:modelValue="taskForm.businessTypeId"
              placeholder="请选择业务类型"
              :options="contractTypeOptions"
              :casProps="casProps"
              style="width: 190px"
            />
          </el-form-item>
          <el-form-item label="预计时长:">
            <el-input-number v-model="taskForm.expectDays" size="small" :min="0" style="margin-right: 8px;"/> 天
          </el-form-item>
          <el-form-item label="备注:">
            <el-input v-model="taskForm.remark" :rows="2" type="textarea" size="small" style="width: 472px;" clearable />
          </el-form-item>
        </el-form>
        <div style="margin-top: 20px;">
          <table class="content" cellspacing="0" width="100%">
            <tr class="top_bg center">
              <td style="width: 15%;height: 40px;text-align: center;">办理步骤</td>
              <td style="width: 35%;height: 40px;text-align: center;">所需资料</td>
              <td style="width: 35%;height: 40px;text-align: center;">备注</td>
              <td style="width: 15%;height: 40px;text-align: center;">操作</td>
            </tr>
            <tr
              class="centers"
              v-for="(item, index) in taskForm.newCrmFlowStepList"
              :key="index"
              v-if="taskForm.newCrmFlowStepList.length > 0"
            >
              <td style="width: 15%">
                <el-input
                  v-model="item.stepName"
                  size="small"
                  style="width: 100%"
                  placeholder="添加步骤"
                />
              </td>
              <td style="width: 35%">
                <el-input
                  v-model="item.infoNames"
                  size="small"
                  style="width: 100%"
                  placeholder="请选择资料"
                  readonly
                >
                  <template #append>
                    <el-icon @click="selectInfo(index)" style="cursor: pointer;"><Search /></el-icon>
                  </template>
                </el-input>
              </td>
              <td style="width: 35%">
                <el-input
                  size="small"
                  style="width: 100%"
                  v-model="item.remark"
                  placeholder="输入内容"
                />
              </td>
              <td style="width: 15%;">
                <div style="display: flex;align-items: center;height: 20px;line-height: 20px;justify-content: center;">
                  <el-icon color="#409efc" style="margin-right: 6px;cursor: pointer;" @click="addTaskList(taskForm.newCrmFlowStepList)"><Plus /></el-icon> |
                  <el-icon color="#409efc" style="margin-left: 6px;cursor: pointer;" @click="delTask(taskForm.newCrmFlowStepList, index)"><Minus /></el-icon>
                </div>
              </td>
            </tr>
            <tr v-else>
              <td style="text-align: center" colspan="7">
                <span style="font-size: 12px;color: #AAAAAA;">暂无相关数据，点击下方按钮快速新增</span>
                <div
                  style="
                    color: var(--themeColor, #17a2b8);
                    cursor: pointer;
                    z-index: 9999;
                    position: relative;
                  "
                  @click="addTaskList(taskForm.newCrmFlowStepList)"
                >
                  +新增
                </div>
              </td>
            </tr>
          </table>
        </div>
      </template>
    </el-drawer>
  </div>

  <!-- 选择资料 -->
  <selectinformation ref="selectinformation" :newCrmFlowStepList="taskForm.newCrmFlowStepList" @success="getInfo"></selectinformation>
</template>

<script>
import { flowCreat,contractTypeList } from "@/api/newCrm.js";
import selectinformation from "../../clue/components/selectInformation.vue"
function deepClone(obj) {
  let objClone = Array.isArray(obj) ? [] : {};
  if (obj && typeof obj === "object") {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        //判断ojb子元素是否为对象，如果是，递归复制
        if (obj[key] && typeof obj[key] === "object") {
          objClone[key] = deepClone(obj[key]);
        } else {
          //如果不是，简单复制
          objClone[key] = obj[key];
        }
      }
    }
  }
  return objClone;
}
export default {
  name: '',
  components: {
    selectinformation
  },
  props: {
  },
  data () {
    return {
      addDrawer: false,
      taskForm: {
        newCrmFlowStepList: []
      },
      contractTypeOptions:[],
      casProps:{
        expandTrigger: "hover",
        label: "name",
        value: "id",
      },
      rules:{
        businessTypeId: [
          { required: true, message: '请选择业务类型', trigger: 'change' }
        ],
        expectDays: [
          { required: true, message: '请填写预计时长', trigger: 'blur' }
        ],
        remark: [
          { required: false, message: '请填写备注', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
    contractTypeList({ contractType: '100' }).then((res) => {
        this.contractTypeOptions = res.data.data.list;
        console.log(this.contractTypeOptions);
      });
  },
  methods: {
    init(type,val){
      this.addDrawer = true
      this.reset()
      if(type == 'edit'){
        this.taskForm = Object.assign({},val);
        this.taskForm.newCrmFlowStepList = deepClone(val.stepList);
        console.log(this.taskForm.newCrmFlowStepList);
      }
    },
     //添加客户资料
     addTaskList(item) {
      item.push({
        stepName: "",
        infoNames: "",
        remark: "",
      });
    },
    reset(){
      this.taskForm = {
        newCrmFlowStepList: []
      }
    },
    saveTask(){
      this.$refs['taskForm'].validate((valid) => {
        if (valid) {
          let sfSave = true
          if (this.taskForm.newCrmFlowStepList.length==0){
            this.$qzfMessage("请添加办理步骤",1)
            return false;
          }else{
            this.taskForm.newCrmFlowStepList.forEach(item => {
              if (item.stepName == '') {
                sfSave = false
                this.$qzfMessage("请填写办理步骤",1)
                return
              }
              if (item.infoNames == '') {
                sfSave = false
                this.$qzfMessage("请选择办理资料",1)
               return
              }
            })
          }
          if(sfSave){
            flowCreat(this.taskForm).then(res => {
              if (res.data.msg == 'success') {
                this.$qzfMessage("保存成功")
                this.$emit('success')
                this.addDrawer = false
              }
              // this.options = res.data.data.list
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //删除客户资料
    delTask(item, index) {
      item.splice(index, 1);
    },

    selectInfo(index){
      this.$refs.selectinformation.init('task',index)
    },
    getInfo(val,index){
      let tempId = []
      let tempName = []
      console.log(index);
      val.forEach(item =>{
        tempId.push(item.id)
        tempName.push(item.label)
      })
      this.taskForm.newCrmFlowStepList[index].infos = tempId.toString();
      this.taskForm.newCrmFlowStepList[index].infoNames = tempName.toString();
      console.log(this.taskForm.newCrmFlowStepList);
    },
  }
}
</script>

<style scoped lang="less">
.drawerHead{
  display: flex;
  justify-content: space-between;
}
.content {
  width: 92%;
  overflow-x: auto; /* 启用水平滚动条 */  
  white-space: nowrap; /* 防止内容换行 */ 
  min-height: 72px;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  position: relative;
  span {
    line-height: 28px;
    font-size: 13px;
  }
  tr {
    padding: 0;
    margin: 0;
  }
  td {
    border-right: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    height: 32px;
    padding: 0 6px;
    color: #333;
    font-size: 13px;
  }
}
</style>
